import socketClient from "socket.io-client";
import historyProvider from './testProvider.js'; // 과거 데이터 관리

// URL에서 coin 파라미터를 추출하여 마켓 정보를 반환하는 함수
function getCoinFromUrl() {
  const params = new URLSearchParams(window.location.search); // URL 파라미터 추출
  return params.get('coin') || 'BTC'; // 기본값으로 'BTC' 설정
}

// coin 값에 따라 마켓 값을 설정하는 함수
function getMarketFromCoin(coin) {
  const markets = {
    BTC: 'BTCUSDT',
    ETH: 'ETHUSDT',
    BNB: 'BNBUSDT',
    BCH: 'BCHUSDT',
    TRX: 'TRXUSDT',

    // 추가 코인에 대한 매핑 가능
  };
  return markets[coin] || 'BTCUSDT'; // 기본값으로 'BTCUSDT' 설정
}

// coin 값에 맞는 market 값 설정
const coin = getCoinFromUrl();
const market = getMarketFromCoin(coin);

// 동적으로 exchange 값을 설정
const exchange = coin; // coin 값을 그대로 exchange로 사용

const socket = socketClient(`https://stream.coinpass.co.kr`, {
  cors: { origin: '*', methods: ["GET", "POST"], credentials: true }
});

var _subs = [];

export default {
  subscribeBars: function(symbolInfo, resolution, updateCb, uid, resetCache) {
    const channelString = createChannelString(market); // 마켓 정보를 채널에 반영
    socket.emit('SubAdd', { subs: [channelString], market }); // 마켓 정보 전송

    const newSub = {
      channelString,
      uid,
      resolution,
      symbolInfo,
      lastBar: historyProvider.history[symbolInfo.name]?.lastBar || null, // 마지막 바를 올바르게 설정
      listener: updateCb,
    };
    _subs.push(newSub);
  },

  unsubscribeBars: function(uid) {
    const subIndex = _subs.findIndex(e => e.uid === uid);
    if (subIndex === -1) {
      console.log("No subscription found for ", uid);
      return;
    }
    const sub = _subs[subIndex];
    socket.emit('SubRemove', { subs: [sub.channelString], market: sub.market });
    _subs.splice(subIndex, 1);
  }
};

// 소켓 이벤트 처리
socket.on('connect', () => {
  console.log(`===Socket connected to market: ${market}`);
});
socket.on('disconnect', (e) => {
  console.log('===Socket disconnected:', e);
});
socket.on('error', err => {
  console.log('====socket error', err);
});

socket.on('/ticker', (e) => {
  if (e.market === market) { // 수신된 데이터가 요청한 마켓과 일치하는지 확인
    console.log("Received data for market: ", market, e);

    const data = {
      sub_type: parseInt(0, 10),
      exchange: exchange, // 동적으로 설정된 exchange 값 사용
      to_sym: e.market,
      from_sym: e.market,
      ts: e.timestamp / 1000,
      volume: e.trade_volume,
      price: e.trade_price,
      change: e.change
    };

    const channelString = `${data.sub_type}~${data.exchange}~${data.to_sym}~${data.from_sym}`;
    const sub = _subs.find(e => e.channelString === channelString);

    if (sub) {
      console.log("volume-거래량 ", data.volume);
      console.log("price-가격 ", data.price);
      console.log("timestamp ", data.ts);
      console.log("lastBartime ", sub.lastBar.time / 1000);
      console.log("change ", data.change);
      if (data.ts < sub.lastBar.time / 1000) {
        return;
      }

      const updatedBar = updateBar(data, sub);
      sub.listener(updatedBar);
      sub.lastBar = updatedBar;
    }
  } else {
    console.warn(`Received data for another market: ${e.market}`);
  }
});

// 봉 업데이트 로직
function updateBar(data, sub) {
  let lastBar = sub.lastBar;
  let resolution = sub.resolution.includes('D') ? 1440 : (sub.resolution.includes('W') ? 10080 : parseInt(sub.resolution));
  const coeff = resolution * 60;
  const rounded = Math.floor(data.ts / coeff) * coeff;
  const lastBarSec = lastBar.time / 1000;

  if (rounded > lastBarSec) {
    return {
      time: rounded * 1000,
      open: lastBar.close,
      high: data.price,
      low: data.price,
      close: data.price,
      volume: data.volume
    };
  } else {
    lastBar.low = Math.min(lastBar.low, data.price);
    lastBar.high = Math.max(lastBar.high, data.price);
    lastBar.volume += data.volume;
    lastBar.close = data.price;
    return lastBar;
  }
}

// 구독 채널 생성
function createChannelString(market) {
  return `0~${exchange}~${market}~${market}`; // 동적으로 설정된 마켓 정보 사용
}
