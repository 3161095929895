import * as React from 'react'; 
import './index.css';
import Datafeed from './api/';
import socketClient from 'socket.io-client';

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// URL에서 coin 파라미터 추출하는 함수
function getCoinFromURL() {
	const params = new URLSearchParams(window.location.search);
	return params.get('coin') || 'BTC';  // 기본값으로 'BTC'를 사용
}

export class TVChartContainer extends React.PureComponent {

	static defaultProps = {
		interval: '1',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'jshunk045@gmail.com',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		timezone: 'Asia/Seoul',
	};

	componentDidMount() {

		// URL에서 coin 파라미터 추출
		const coin = getCoinFromURL();  // BTC, ETH 등
		const coinSymbols = {
			BTC: 'BTCUSDT',
			ETH: 'ETHUSDT',
			BNB: 'BNBUSDT',
			BCH: 'BCHUSDT',
			TRX: 'TRXUSDT'
		};
		const symbol = coinSymbols[coin] || 'BTCUSDT';

		// 차트 위젯 옵션 초기화
		const widgetOptions = {
			debug: false,
			symbol: symbol,
			datafeed: Datafeed,
			interval: this.props.interval,
			container_id: this.props.containerId,
			library_path: this.props.libraryPath,
			locale: getLanguageFromURL() || 'ko',
			timezone: this.props.timezone || "Asia/Seoul",
			disabled_features: ['left_toolbar','header_screenshot','header_saveload','header_fullscreen_button','header_settings','show_trading_notifications_history','header_logarithmic'],
			enabled_features: ['study_templates'],
			time_frames: [
				{ text: '15', resolution: '1', description: '15분' }
			],
			charts_storage_url: this.props.chartsStorageUrl,
			charts_storage_api_version: this.props.chartsStorageApiVersion,
			client_id: this.props.clientId,
			user_id: this.props.userId,
			fullscreen: false,
			autosize: true,
			studies_overrides: this.props.studiesOverrides,
			theme: "dark",
			overrides: {
				// 캔들 색상
				"mainSeriesProperties.candleStyle.upColor": "#e15241",
				"mainSeriesProperties.candleStyle.downColor": "#4880ee",
				// 배경 색상
				"paneProperties.background": "#161a25",  // 검정 배경 (다크 테마)
				// 그리드 라인 색상 및 스타일
				"paneProperties.vertGridProperties.color": "#424242", // 수직 그리드 색상
				"paneProperties.horzGridProperties.color": "#424242", // 수평 그리드 색상
				"paneProperties.horzGridProperties.style": 1, // 수직 그리드를 점선으로 설정 (1 = 점선)
				"paneProperties.vertGridProperties.style": 1, // 수평 그리드를 점선으로 설정 (1 = 점선)
				"paneProperties.separatorColor": "#9598a1",

				// 텍스트 색상 (모든 텍스트를 흰색으로 설정)
				"scalesProperties.lineColor": "#D3D3D3",
				"scalesProperties.textColor": "#FFFFFF",  // 축 텍스트 흰색
				"priceScale.textColor": "#FFFFFF",        // 가격 텍스트 흰색
				"timeScale.textColor": "#FFFFFF",         // 시간 텍스트 흰색
				
				// 범례 및 정보 텍스트 설정
				"paneProperties.legendProperties.showStudyArguments": true,
				"paneProperties.legendProperties.showStudyTitles": true,
				"paneProperties.legendProperties.showStudyValues": true,
				"paneProperties.legendProperties.showSeriesTitle": true,
				"paneProperties.legendProperties.showSeriesOHLC": true,
			}
		};

		// 차트 초기화
		Datafeed.onReady(() => {
			const widget = (window.tvWidget = new window.TradingView.widget(widgetOptions));

			widget.onChartReady(() => {
				console.log('Chart has loaded!');
				
				// 서버에서 데이터 가져오기
				fetch(`/api/bars?coin=${coin}`)
					.then((response) => response.json())
					.then((data) => {
						widget.applyOverrides({
							"mainSeriesProperties.candleStyle.upColor": data.upColor || "#e15241",   // 상승 색상
							"mainSeriesProperties.candleStyle.downColor": data.downColor || "#4880ee",  // 하락 색상
							"paneProperties.background": data.backgroundColor || "#000000",  // 배경 색상
						});
						// 받은 데이터의 마지막 두 개의 바 데이터
						const latestBar = data.data[data.data.length - 1];
						const latestTimestamp = latestBar.time;
						//const latestTimestampInMillis = latestTimestamp * 1000;
						console.log('1 latestTimestamp', latestTimestamp);
						// 마지막 바에서 60분 이전의 데이터까지 보이게 설정 (1분 단위 줌)
						widget.activeChart().setVisibleRange({
							from: latestTimestamp - 60 * 90,  // 60분 전
							to: latestTimestamp,              // 현재 시간
							applyDefaultRightMargin: true     // 오른쪽 여백 유지
							
						});
						
						if(first)
						{
							console.log('zoomOut ', widget.activeChart().canZoomOut());
							if(widget.activeChart().canZoomOut()) {
								console.log('zoomOut2 ', widget.activeChart().canZoomOut());
								widget.activeChart().zoomOut();
							};
				
						}
						
					})
					.catch((error) => console.error('차트 데이터 가져오기 오류:', error));
			});
			
			
			
		});
	}

	render() {
		return (
			<div
				id={this.props.containerId}
				className={'TVChartContainer'}
			/>
		);
	}
}
